import React from 'react';
import { CRITICAL, WARNING } from '../batteryOverview/utils/BatteryDataUtils';

type barChartTypes = {
    value?: number;
    status?: string;
};

const BarChart = ({ value, status }: barChartTypes) => {
    let statusClassName: string = ' progress-bar';
    switch (status) {
        case WARNING:
            statusClassName = `${statusClassName}-warning`;
            break;
        case CRITICAL:
            statusClassName = `${statusClassName}-danger`;
            break;
        default:
            statusClassName = '';
    }

    return (
        <div className="width-100pct display-flex align-items-center">
            <div className="progress margin-0 flex-1-0">
                <div
                    data-testid={'progress-bar'}
                    className={`progress-bar${statusClassName}`}
                    style={{ width: `${value}%` }}
                />
            </div>
            <div className={'margin-left-10'}>{`${value}%`}</div>
        </div>
    );
};

export default BarChart;
