import { FormattedDate, FormattedTime } from 'react-intl';

type PanelBodyTypes = {
    date: string | undefined;
};

const PanelBody = ({ date }: PanelBodyTypes) => {
    return (
        <div data-testid="date-panel" className={'display-flex flex-wrap text-color-dark'}>
            <span>
                <FormattedDate value={date} /> <FormattedTime value={date} />
            </span>
        </div>
    );
};

export default PanelBody;
