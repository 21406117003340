import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../setup/store';

export const FEATURE_SLICE_NAME = 'featureToggle';

export const FT_HISTORY_GRAPH = 'enable_history_graph';
export const FT_HISTORICAL_BATTERY_CONDITIONS = 'ft_historical_battery_conditions';
export const FT_SOH_DATA = 'ft_soh_data';

export const CURRENT_TOGGLES = [FT_HISTORY_GRAPH, FT_HISTORICAL_BATTERY_CONDITIONS, FT_SOH_DATA] as const;

export type KnownToggles = TypeFromArray<typeof CURRENT_TOGGLES>;

/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
type TypeFromArray<T extends readonly unknown[]> = T extends readonly (infer TypeFromArray)[] ? TypeFromArray : never;

export type EventContent = {
    featureKey: KnownToggles;
    value: string | undefined;
};

export type FeatureToggleState = {
    features: {
        [key in KnownToggles]: boolean;
    };
};

const initialState: FeatureToggleState = {
    features: {
        [FT_HISTORY_GRAPH]: false,
        [FT_HISTORICAL_BATTERY_CONDITIONS]: false,
        [FT_SOH_DATA]: false,
    },
};

const featureToggleSlice = createSlice({
    initialState,
    name: FEATURE_SLICE_NAME,
    reducers: {
        featureToggleChanged: (state: FeatureToggleState, action: PayloadAction<EventContent>) => {
            const { featureKey, value } = action.payload;
            if (CURRENT_TOGGLES.includes(featureKey)) {
                state.features[featureKey] = value !== undefined ? /true/i.test(value) : false;
            }
        },
    },
});

export const selectFeaturesState = (state: RootState) => state[FEATURE_SLICE_NAME];

// This is a selector, needs to be used with useAppSelector hook
export const isFeatureEnabled = (feature: KnownToggles) => (state: RootState) =>
    state[FEATURE_SLICE_NAME].features[feature];

export const { featureToggleChanged } = featureToggleSlice.actions;
export default featureToggleSlice.reducer;
