import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import { useEffect, useRef, useState } from 'react';

import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import InternalErrorState from '../../app/InternalErrorState';
import { BATTERY_SERVICE_ASSET_SELECTED, BATTERY_SERVICE_READY, sendMessage } from '../../app/MessageHandler';
import {
    detailOpened,
    getFilters,
    getViewType,
    hasReceivedFilters,
    isDetailOpen,
} from '../slices/batteryOverviewSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { renderTableHeader } from './DiagnosticsTableHead';
import { useGetBatteryOverviewQuery } from '../api/batteryOverviewSlice';
import {
    AvailableAfter20000km,
    createRequestBody, getSohStatusCssClass,
    getSortDir,
    LOW_MILEAGE, OK,
    NoData,
} from '../utils/BatteryDataUtils';
import SortDirection, { type SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';
import Popover from '@rio-cloud/rio-uikit/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { isFeatureEnabled, FT_SOH_DATA } from '../../../configuration/featureToggle/featureToggleSlice';
import SohBanner from '../../battery/SohBanner';

const renderAsset = (asset: Asset) => {
    return (
        <>
            <span className={'text-bold margin-right-5 white-space-nowrap'}>
                {asset.type && <span className={`rioglyph rioglyph-${asset.type} margin-right-5`} />}
                <span className="margin-right-5">{asset.name}</span>
                <span className="label label-primary label-condensed height-20 padding-top-2">
                    <span className="text-bold text-uppercase">{asset.product}</span>
                </span>
            </span>
        </>
    );
};

const ACTIVE_CLASS = 'active';
const DATA_ATTRIBUTE = 'data-key';
const SORT_BY_ATTRIBUTE = 'data-sortby';

const tooltipWrapperClassNames =
    'max-width-250 bg-white text-color-black display-flex flex-column align-items-start margin-5';
const tooltipSpacerClassNames =
    'margin-0 margin-bottom-5 border border-color-blue border-top-only border-width-5 width-100pct';

const highlightRow = (rows: any[] | undefined, value: string | undefined) => {
    const row = getRowByDataAttribute(rows, value);
    if (row) {
        row.classList.add(ACTIVE_CLASS);
    }
};

const removeHighlightFromRow = (rows: any[] | undefined, value: string | undefined) => {
    const row = getRowByDataAttribute(rows, value);
    if (row) {
        row.classList.remove(ACTIVE_CLASS);
    }
};

const getRowByDataAttribute = (rows: any = [], value = '', attribute = DATA_ATTRIBUTE) =>
    rows.find((row: any) => {
        const dataAttribute = row.attributes[attribute];
        if (dataAttribute) {
            return dataAttribute.value === value;
        }
        return false;
    });

const ContentLoadingScreen = () => {
    const intl = useIntl();
    return (
        <div data-testid="loading-screen" style={{ top: '100px' }} className="position-absolute width-100pct z-index-1">
            <Spinner text={intl.formatMessage({ id: 'batteryService.loading' })} />
        </div>
    );
};

export interface DiagnosticsTableData {
    assets: BatteryData[];
    hasNext: boolean;
}

export interface BatteryData {
    soh_status: string;
    asset_name: Asset;
    soh: string | undefined;
    mileage: string | undefined;
    usage_time: string | undefined;
    disch_energy_thru: string | undefined;
}

type Asset = {
    id: string;
    name: string;
    type: string;
    product: string;
};

export let appIntl: IntlShape;

const defaultColumnOrder = ['asset_name', 'soh', 'mileage', 'usage_time', 'disch_energy_thru'];

const DiagnosticsTable = () => {
    const intl = useIntl();
    appIntl = intl;
    const dispatch = useAppDispatch();
    const isSoHStatusFeatureToggleEnable = useAppSelector(isFeatureEnabled(FT_SOH_DATA));

    const [activeRowId, setActiveRowId] = useState('');
    const [lastActiveRowId, setLastActiveRowId] = useState('');

    const columnLabels: { [key: string]: string } = {
        asset_name: intl.formatMessage({ id: 'batteryService.vehicle' }),
        soh: intl.formatMessage({ id: 'batteryService.stateOfHealth' }),
        mileage: intl.formatMessage({ id: 'batteryService.totalMileage' }),
        usage_time: intl.formatMessage({ id: 'batteryService.batteryLifetime' }),
        disch_energy_thru: intl.formatMessage({ id: 'batteryService.dischargedEnergyThroughput' }),
    };

    // ---------------------------------------------------

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [sortBy, setSortBy] = useState('asset_name');
    const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);
    const viewType = useAppSelector(getViewType);
    const detailOpen = useAppSelector(isDetailOpen);
    const filters = useAppSelector(getFilters);
    const receivedFilters = useAppSelector(hasReceivedFilters);
    const [isLoadingMoreContent, setIsLoadingMoreContent] = useState<boolean>(false);

    const [batteryData, setBatteryData] = useState<DiagnosticsTableData>({
        assets: [],
        hasNext: false,
    });

    if (!receivedFilters) {
        sendMessage({
            type: BATTERY_SERVICE_READY,
            payload: {},
        });
    }

    // Immediate execution query
    const {
        data: initialBatteryData,
        isError,
        error,
        isFetching,
        refetch,
    } = useGetBatteryOverviewQuery(
        createRequestBody(pageNumber, filters.selectedAssets, filters.selectedCriticality, sortBy, sortDir),
    );

    const handleSortChange = (event: any) => {
        const newSortBy = event.currentTarget.getAttribute(SORT_BY_ATTRIBUTE);
        setSortBy(newSortBy);
        setSortDir(getSortDir(newSortBy, sortBy, sortDir));
        setPageNumber(1);
    };

    // ---------------------------------------------------

    // @ts-ignore
    let tableRef: HTMLTableElement | null = useRef(null);

    // --------------------------------------------------
    useEffect(() => {
        if (pageNumber === 1) {
            setBatteryData({
                assets: [...(initialBatteryData?.assets || [])],
                hasNext: initialBatteryData?.hasNext || false,
            });
        } else {
            setBatteryData({
                assets: [...batteryData.assets, ...(initialBatteryData?.assets || [])],
                hasNext: initialBatteryData?.hasNext || false,
            });
        }
        setIsLoadingMoreContent(false);
    }, [initialBatteryData]);
    // --------------------------------------------------

    useEffect(() => {
        if (!tableRef || !tableRef.rows) {
            return;
        }
        const rows = [...tableRef.rows];

        if (!detailOpen) {
            setLastActiveRowId(activeRowId);
            setActiveRowId('');
        }

        if (lastActiveRowId !== activeRowId) {
            removeHighlightFromRow(rows, lastActiveRowId);
            highlightRow(rows, activeRowId);
        }
    });

    const handleLoadMore = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setPageNumber(pageNumber + 1);
        setIsLoadingMoreContent(true);
    };

    const handleActiveRowChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const rowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);

        if (detailOpen && rowId === activeRowId) {
            return;
        }

        setLastActiveRowId(activeRowId);
        setActiveRowId(activeRowId === rowId ? '' : rowId);

        sendMessage({
            type: BATTERY_SERVICE_ASSET_SELECTED,
            payload: {
                asset_id: rowId,
            },
        });

        dispatch(detailOpened());
    };

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        'table-hover',
        { 'table-cards table-single-card': viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD },
        { 'table-cards table-multi-cards': viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS },
        { 'opacity-50': isFetching && !isLoadingMoreContent },
    );

    if (isError) {
        return (
            <InternalErrorState
                errorCode={(error as FetchBaseQueryError).status as number}
                reloadTriggerFunction={refetch}
            />
        );
    }

    const LoadMore = () => {
        return (
            <div className={'display-flex justify-content-center non-printable'}>
                <button id="load-more-button" className={'btn btn-link'} onClick={handleLoadMore}>
                    <span className={'icon rioglyph rioglyph-arrow-down'} />
                    <FormattedMessage id="batteryService.loadMore" />
                    {isFetching && isLoadingMoreContent && (
                        <span className="margin-left-10">
                            <Spinner />
                        </span>
                    )}
                </button>
            </div>
        );
    };

    const renderTableCell = (row: BatteryData, col: string) => {
        if (col === 'asset_name') {
            return renderAsset(row.asset_name);
        } else if (col === 'soh') {
            if (isSoHStatusFeatureToggleEnable) return renderSohRow(row);
            else return <NoData textSize={14} />;
        } else if (col === 'mileage') {
            return renderData(row.mileage);
        } else if (col === 'usage_time') {
            return renderData(row.usage_time);
        } else if (col === 'disch_energy_thru') {
            return renderData(row.disch_energy_thru);
        } else {
            return <NoData textSize={14} />;
        }
    };

    const renderData = (data: string | undefined) => {
        if (data !== undefined) {
            return <span>{data}</span>;
        } else {
            return <NoData textSize={14} />;
        }
    };

    const Table = () => {
        return (
            <div id="TableBatteryDiagnostics" data-testid="table-battery-diagnostics">
                <div>
                    <table ref={(node) => (tableRef = node)} className={tableClassNames}>
                        <colgroup>
                            {defaultColumnOrder.map((column) => (
                                <col key={column} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                {defaultColumnOrder.map((column) =>
                                    renderTableHeader(column, columnLabels[column], handleSortChange, sortBy, sortDir),
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {batteryData.assets.map((row, index) => (
                                <tr
                                    className={
                                        classNames('cursor-pointer', [`${getSohStatusCssClass(row.soh_status)}`])
                                }
                                    key={index}
                                    data-key={row.asset_name.id}
                                    onClick={handleActiveRowChange}
                                >
                                    {defaultColumnOrder.map((col) => {
                                        return (
                                            <td key={col} data-field={columnLabels[col]}>
                                                {renderTableCell(row, col)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                            {/* Placeholder workaround for equal with cards of the last row */}
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                            <tr className="table-card-placeholder" />
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const renderTable = () =>
        isEmpty(batteryData.assets) && !isFetching ? (
            <NotFoundState
                headline={intl.formatMessage({ id: 'batteryService.diagnosticsTable.notFoundState.headline' })}
                message={intl.formatMessage({ id: 'batteryService.diagnosticsTable.notFoundState.message' })}
            />
        ) : (
            <>
                {!isSoHStatusFeatureToggleEnable && <SohBanner />}
                <Table />
            </>
        );

    const renderSohRow = (row: BatteryData) => {
        if (row.soh_status === OK) {
            return renderData(row.soh);
        } else if (row.soh_status === LOW_MILEAGE) {
            const SoHUnavailableTooltip = (
                <Popover id={'sohUnavailable-popover'} className={'margin-left-5'} placement={'right'}>
                    <div className={tooltipWrapperClassNames}>
                        <h6>
                            <FormattedMessage id="batteryService.stateOfHealth.unavailable" />
                        </h6>
                        <hr className={tooltipSpacerClassNames} />
                        <div className="max-width-250 bg-white text-color-black">
                            <span>
                                {intl.formatMessage({
                                    id: 'batteryService.stateOfHealth.unavailable.reason',
                                })}
                            </span>
                        </div>
                    </div>
                </Popover>
            );
            return (
                <div>
                    <AvailableAfter20000km textSize={14} />
                    <OverlayTrigger placement="right" overlay={SoHUnavailableTooltip}>
                        <span
                            className="rioglyph rioglyph-exclamation-sign text-color-dark
                            text-size-16 width-auto margin-top-0 margin-left-5"
                        />
                    </OverlayTrigger>
                </div>
            );
        } else return <NoData textSize={14} />;
    };

    return (
        <>
            {isFetching && !isLoadingMoreContent && <ContentLoadingScreen />}
            {renderTable()}
            {((!isFetching && batteryData.hasNext) || isLoadingMoreContent) && <LoadMore />}
        </>
    );
};

export default DiagnosticsTable;
