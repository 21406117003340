import React from 'react';
import classNames from 'classnames';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import { Placement } from '@rio-cloud/rio-uikit/values/Placement';

const tooltipWrapperClassNames =
    'max-width-250 bg-white text-color-black display-flex flex-column align-items-start margin-10';
const tooltipSpacerClassNames =
    'margin-0 margin-bottom-5 border border-color-blue border-top-only border-width-5 width-100pct';

export const renderTableHeader = (
    column: React.Key,
    label: string | undefined,
    handleSortChange: (event: any) => void,
    sortBy: string,
    sortDir: string,
) => {
    const renderTooltip = (placement: Placement, title: string, body: string) => (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip id={'customTooltip'} textAlignment={'left'}>
                    <div className={tooltipWrapperClassNames}>
                        <h6>
                            <FormattedMessage id={title} />
                        </h6>
                        <hr className={tooltipSpacerClassNames} />
                        <span>
                            <FormattedMessage
                                id={body}
                                values={{
                                    b: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </span>
                    </div>
                </Tooltip>
            }
        >
            <span className="rioglyph rioglyph-info-sign margin-left-5 text-color-dark" />
        </OverlayTrigger>
    );

    return (
        <th
            key={column}
            className={classNames('user-select-none', 'sort-column')}
            onClick={handleSortChange}
            data-field={column}
            data-sortby={column}
            title={label}
        >
            <span>
                {sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows />}
                <span>{label}</span>
                {column === 'soh' &&
                    renderTooltip(
                        'right',
                        'batteryService.stateOfHealth',
                        'batteryService.stateOfHealth.tooltip.information',
                    )}
                {column === 'disch_energy_thru' &&
                    renderTooltip(
                        'left',
                        'batteryService.dischargedEnergyThroughput',
                        'batteryService.dischargedEnergyThroughput.tooltip.body',
                    )}
            </span>
        </th>
    );
};
